.cls-1 {
    fill: #019147;
  }

  .cls-2 {
    fill: #ea1f29;
  }

  .cls-3 {
    isolation: isolate;
  }
  .cls-4 {
    fill: #fff;
  }