.react-calendar {
    width: 400px;
    max-width: 100%;
    background-color: #fff;
    color: #222;
    border-radius: 8px;
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
    font-family: Arial, Helvetica, sans-serif;
    line-height: 1.125em;
}
.react-calendar__navigation button {
    color: #009343;
    min-width: 44px;
    background: none;
    font-size: 16px;
    margin-top: 8px;
}
.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
    background-color: #f8f8fa;
}
.react-calendar__navigation button[disabled] {
    background-color: #f0f0f0;
}
abbr[title] {
    text-decoration: none;
}
/* .react-calendar__month-view__days__day--weekend {
    color: #d10000;
   } */
.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
    background: #f8f8fa;
    color: #009343;
    border-radius: 50%;
}
.react-calendar__tile--now {
    background: #6f48eb33;
    border-radius: 50%;
    font-weight: bold;
    color: #009343;
}
.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
    background: #6f48eb33;
    border-radius: 50%;
    font-weight: bold;
    color: #009343;
}
.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
    background: #f8f8fa;
}
.react-calendar__tile--active {
    background: #009343;
    border-radius: 50%;
    font-weight: bold;
    color: white;
}
.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
    background: #009343;
    color: white;
}
.react-calendar--selectRange .react-calendar__tile--hover {
    background-color: #009343;
}
.react-calendar__tile--range {
    color: #f8f8fa;
    background: #009343;
    border-radius: 0;
}
.react-calendar__tile--rangeStart {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-top-left-radius: 50%;
    border-bottom-left-radius: 50%;
    background: #009343;
    color: white;
}
.react-calendar__tile--rangeEnd {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: 50%;
    border-bottom-right-radius: 50%;
    background: #009343;
    color: white;
}
